import * as React from "react";
import { Link } from "gatsby";
import DefautLayout from "../layouts/default";

const IndexPage = () => {
  return (
    <DefautLayout title="Datenschutzerklärung">
      <div className="content p-standard">
      <section id="header">
        <h1 className="pb-20">
          Datenschutzerklärung der Digitalschmiede Bayern
        </h1>
        <p>
          Die Digitalschmiede Bayern ist ein Programm der byte - Bayerischen Agentur für Digitales.
          <br />
          Diese Datenschutzerklärung bezieht sich auf die Verarbeitung personenbezogener Daten durch die byte - Bayerische Agentur für Digitales GmbH im Rahmen dieses Internetauftritts, einschließlich der dort angebotenen Dienste sowie weiterer Tätigkeiten.
        </p>
        </section>

        <h2 className="pt-50 pb-20">A) Allgemeine Informationen</h2>
        <ol>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Name und Kontaktdaten des Verantwortlichen
            </p>
            <p>
              byte - Bayerische Agentur für Digitales GmbH
              <br />
              Oskar-von-Miller-Ring 35
              <br />
              80333 München
              <br />
              Telefon: 0162 / 2019793 <br />
              E-Mail:{' '}
                <a href="mailto:datenschutz@byte.bayern">
                datenschutz@byte.bayern
                </a>
              <br />
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
            Rechtsgrundlagen für die Verarbeitung personenbezogener Daten
            </p>
            <ul>
              <li>Diese Datenschutzerklärung bezieht sich auf die Verarbeitung personenbezogener Daten. Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Artikel 6 Absatz 1 lit. a DSGVO (Datenschutzgrundverordnung) als Rechtsgrundlage.</li>
              <li>Bei der Verarbeitung personenbezogener Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich sind, dient Artikel 6 Absatz 1 lit. b DSGVO als Rechtsgrundlage. Dies schließt Verarbeitungsvorgänge ein, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind.</li>
              <li>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der wir unterliegen, dient Artikel 6 Absatz 1 lit. c DSGVO als Rechtsgrundlage.</li>
              <li>Für den Fall, dass lebenswichtige Interessen einer betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Artikel 6 Absatz 1 lit. d DSGVO als Rechtsgrundlage.</li>
              <li>Für den Fall, dass die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt, ist Art. 6 Abs. 1 lit. e DSGVO i.V.m. BayDSG (Bayerisches Datenschutzgesetz) die Rechtsgrundlage.</li>
              <li>Für den Fall, dass die Verarbeitung zur Wahrung der berechtigten Interessen von uns oder eines Dritten erforderlich ist, dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage.</li>
            </ul>
          </li> 
          <li>
            <p className="fw-bold pb-10 pt-20">
              Empfänger von personenbezogenen Daten
            </p>
            <p>
            Das Internetangebot der Digitalschmiede Bayern wird gehostet durch<br /><br />
              Amazon Web Services Germany GmbH <br />
              Krausenstr. 38 <br />
              10117 Berlin <br />
              Internet: https://www.amazon.de <br />
                    <br /> <br />
                    als Auftragsverarbeiter. Sofern nicht anders angegeben, werden nachfolgend erläuterte Daten dort gespeichert. Gegebenenfalls werden die Daten auch aufgrund gesetzlicher Verpflichtung an die zuständigen Aufsichts- und Rechnungsprüfungsbehörden zur Wahrnehmung der jeweiligen Kontrollrechte übermittelt.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Dauer der Speicherung der personenbezogenen Daten
            </p>
            <p>
            Ihre Daten werden nur so lange gespeichert, wie dies für die Erreichung des jeweiligen Speicherzwecks erforderlich oder durch gesetzliche Aufbewahrungsfristen vorgeschrieben ist.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Rechte der betroffenen Person</p>
            <p>
            Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:
              <ul className="pl-50">
                <li>
                das Recht auf Auskunft nach Artikel 15 DSGVO,
                </li>
                <li>
                das Recht auf Berichtigung nach Artikel 16 DSGVO,
                </li>
                <li>
                das Recht auf Löschung nach Artikel 17 DSGVO,
                </li>
                <li>
                das Recht auf Einschränkung der Verarbeitung nach Artikel 18 DSGVO sowie
                </li>
                <li>
                das Recht auf Datenübertragbarkeit aus Artikel 20 DSGVO.
                </li>
              </ul>
              Soweit eine Verarbeitung auf einer von Ihnen erteilten Einwilligung beruht (Artikel 6 Absatz 1 lit. a, Artikel 9 Absatz 2 lit. a DSGVO), haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit, der aufgrund der Einwilligung bis zu Ihrem Widerruf erfolgten Verarbeitung, wird hierdurch nicht berührt. Wenden Sie sich für einen Widerruf bitte über die Mailadresse datenschutz@byte.bayern an uns.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
            Widerspruchsrecht
            </p>
            <p>
            Unter den Voraussetzungen des Art. 21 DSGVO können Sie jederzeit der weiteren Speicherung und Verwendung Ihrer Daten durch eine Mitteilung an datenschutz@byte.bayern widersprechen.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Beschwerderecht bei der Aufsichtsbehörde</p>
            <p>
              Weiterhin besteht ein Beschwerderecht (Art. 77 DSGVO) beim Bayerischen Landesbeauftragten für den Datenschutz (BayLfD). Diesen können Sie unter folgenden Kontaktdaten erreichen:          <br />          <br />
              Postanschrift: Postfach 22 12 19, 80502 München          <br />
              Adresse: Wagmüllerstraße 18, 80538 München          <br />
              Telefon: 089 / 212672-0          <br />
              Telefax: 089 / 212672-50          <br />
              E-Mail: poststelle@datenschutz-bayern.de          <br />
              Internet: www.datenschutz-bayern.de
            </p>
          </li>
        </ol>
        <h2 className="pt-50 pb-20">B) Informationen zum Internetauftritt</h2>
        <ol>
          <li>
            <p className="fw-bold pb-10 pt-20">Protokollierung</p>
            <p>
            Wenn Sie diese oder andere Internetseiten aufrufen, übermitteln Sie über Ihren Internetbrowser Daten an unseren Webserver. Die folgenden Daten werden während einer laufenden Verbindung zur Kommunikation zwischen Ihrem Internetbrowser und unserem Webserver aufgezeichnet:
              <ul className="pl-50">
                <li>Datum und Uhrzeit der Anforderung</li>
                <li>Name der angeforderten Datei</li>
                <li>Seite, von der aus die Datei angefordert wurde</li>
                <li>
                  Zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)
                </li>
                <li>verwendete Webbrowser und verwendetes Betriebssystem</li>
                <li>vollständige IP-Adresse des anfordernden Rechners</li>
                <li>übertragene Datenmenge.</li>
              </ul>
              Aus Gründen der technischen Sicherheit, insbesondere zur Abwehr von Angriffsversuchen auf unseren Webserver, werden diese Daten von uns gespeichert. Nach spätestens sieben Tagen werden die Daten durch Verkürzung der IP-Adresse auf Domain-Ebene anonymisiert, so dass es nicht mehr möglich ist, einen Bezug auf einzelne Nutzer herzustellen.
              <br />
              <br />
              Zur Abwehr von Gefahren für die Sicherheit in der Informationstechnik werden die Daten an das Landesamt für Sicherheit in der Informationstechnik weitergeleitet und dort auf Grundlage der Art. 12 ff. des Bayerischen E-Government-Gesetzes verarbeitet.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Aktive Komponenten</p>
            <p>
            Wir verwenden aktive Komponenten wie Javascript, Java-Applets oder Active-X-Controls. Diese Funktion kann durch die Einstellung Ihres Internetbrowsers von Ihnen abgeschaltet werden.
            </p>
          </li>
          {/* <li>
            <p className="fw-bold pb-10 pt-20">
              Datenschutzbestimmung für den Einsatz der Vorlesetechnik
              Readspeaker
            </p>
            <p>
              a) ReadSpeaker, ein Dienst der ReadSpeaker Holding B.V.,
              Dolderseweg 2A, Huis ter Heide 3712 BP, Niederlande, der die
              Inhalte unserer Webseite vorliest. Beim Klick auf den
              „Vorlesen“-Button, wird der markierte Text über die IP des Nutzers
              an den ReadSpeaker-Server übergeben und dort eine Audiodatei
              generiert und als Stream an die IP-Adressen des Nutzers
              zurückgesendet. Sofort nach Auslieferung der Audiodatei werden der
              Vorgang und die IP-Adresse des Nutzers auf dem ReadSpeaker-Server
              gelöscht. ReadSpeaker speichert keine personenbezogenen Daten. Die
              Daten werden ausschließlich in der Europäischen Union realisiert
              (Schweden und Irland).
              <br />
              <br />
              b) Bei Nutzung der ReadSpeaker-Funktion werden technisch
              notwendige Cookies auf Ihrem Endgerät gespeichert, um die vom
              Benutzer gewählten Einstellungen beizubehalten
              (Hervorhebungseinstellung, Textgröße usw.) und nach der Session
              bzw. je nach Funktion einem entsprechenden Zeitraum (maximal 30
              Tage) wieder gelöscht. Wird die ReadSpeaker-Funktion nicht
              aktiviert, werden beim Besuch der Webseite keine Cookies auf dem
              Endgerät gespeichert. Um diesen Dienst zu benutzen, müssen Sie den
              vorzulesenden Text markieren und das erscheinende
              Lautsprecher-Symbol anklicken. Die Datenschutzerklärung von
              ReadSpeaker finden Sie unter
              <Link to="https://www.readspeaker.com/de/erklarung-zum-datenschutz/">
                https://www.readspeaker.com/de/erklarung-zum-datenschutz/
              </Link>
              .
            </p>
          </li> */}
         
  
        </ol>
        <h2 className="pt-50 pb-20">
          C) Informationen zu einzelnen Verarbeitungen
        </h2>
        <ol>
          <li>
            <p className="fw-bold pb-10 pt-20">Elektronische Post (E-Mail)</p>
            <p>
            Für Ihre Kontaktaufnahme mit uns bieten wir Ihnen die Nutzung von E-Mail-Adressen. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt ausschließlich zum Zweck der Bearbeitung Ihres Anliegens, wie z. B. der Beantwortung Ihrer Frage oder Beschwerde.
Bei der Kontaktaufnahme mit uns per E-Mail werden die von Ihnen freiwillig mitgeteilten Daten gespeichert, damit wir auf Ihr Anliegen eingehen können. Das kann folgende Daten betreffen:
          <ul>
            <li>Betreff</li>
            <li>Ihre Nachricht</li>
            <li>Anrede</li>
            <li>Name</li>
            <li>Vorname</li>
            <li>E-Mail-Adresse</li>
            <li>Straße</li>
            <li>Hausnummer</li>
            <li>Postleitzahl</li>
            <li>Ort</li>
            <li>Telefonnummer</li>
            <li>IP-Adresse</li>
          </ul>
              <br />
              <br />
              Die personenbezogenen Daten, die wir per E-Mail erhalten, löschen wir, sobald die Speicherung nicht mehr für den von Ihnen verfolgten Zweck oder im Zusammenhang mit etwaigen Aufbewahrungspflichten erforderlich ist.

              <br />
              <br />
              Rechtsgrundlage für die Speicherung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. a und f DSGVO, d.h. die Speicherung erfolgt mit Ihrer Einwilligung und zur Erfüllung öffentlicher Aufgaben.
          
            </p>
          </li>
          
        <li>
          <p className="fw-bold pb-10 pt-20">Fotoaufnahmen</p>
    
    
        <p>Im Rahmen unserer Presse- und Öffentlichkeitsarbeit werden bei Veranstaltungen und Terminen Fotos und Videos aufgenommen, auf denen Sie gegebenenfalls erkennbar zu sehen sind. Der Aufnahme und/oder einer Veröffentlichung können Sie widersprechen.</p>
        <br />
              <br />
              <p>Bitte nutzten Sie für Ihren Widerspruch die oben genannten Kontaktdaten.</p>
              </li>
              <li>
            <p className="fw-bold pb-10 pt-20">Bewerbungen als Fellow</p>


        <p>byte - Bayerische Agentur für Digitales erhebt und verarbeitet die personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber entsprechende Bewerbungsunterlagen auf dem elektronischen Wege, beispielsweise per E-Mail, übermittelt. Kommt es zu einem Beschäftigungsverhältnis mit einem Bewerber, werden die übermittelten Daten zum Zwecke der Abwicklung des Beschäftigungsverhältnisses unter Beachtung der gesetzlichen Vorschriften gespeichert. Zudem können Daten im Einstellungsverfahren an Partner, die für die Abwicklung des Beschäftigungsverhältnisses zuständig sind und dem Bewerber vor Weitergabe bekannt gegeben werden, übermittelt werden. Kommt es zu keinem Beschäftigungsverhältnis, so werden die Bewerbungsunterlagen nach Bekanntgabe der Absageentscheidung nach den einschlägigen Vorschriften gelöscht, sofern einer Löschung keine sonstigen berechtigten Interessen byte – Bayerische Agentur für Digitales GmbH entgegenstehen. Sonstiges berechtigtes Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).</p>
        <br />
              <br />
          <p>Rechtsgrundlage der Verarbeitung der personenbezogenen Daten sind Art. 6 Abs. 1 lit a DSGVO und Art. 6 Abs. 1 lit b DSGVO.</p>
          </li>
        </ol>

      </div>
    </DefautLayout>
  );
};

export default IndexPage;
